import logo from './logo.png';
import mobile from './Mobile.png';
import screen1 from './Screen1.png';
import screen from './Screen.png';
import audience from './Audience.png';
import group5 from './Group5.png';
import './App.css';
import './fullpage.js/dist/fullpage.css'
import { BrowserRouter as Router, Routes, Route, NavLink, Link } from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';
import fullpage from 'fullpage.js';


function App() {
  //mailchimp api: 0ef9910d9e8699315cc4a6fec5f6af92-us9
  // const [msg, setMsg] = useState('no');
  const subscribeToMailchimp = async (email, name) => {
    const proxyUrl = 'http://home.pindogo.com/cors-proxy.php'; // Adjust the URL if necessary
    const data = {
          "email_address": email,
          "status": "subscribed",
          "merge_fields": {
            "FNAME": name
        }
      
    };
  
    try {
      const response = await fetch(proxyUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
  
      const result = await response.json();
      // console.log('User subscribed successfully!', result);
      // setMsg('yes');
      document.getElementById("msg").style.display="block";
      setFormData({ email: '', name: '' });
    } catch (error) {
      console.error('Error subscribing user:', error);
    }
  };
  


  const [formData, setFormData] = useState({
      email: '',
      name: ''
    });
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      subscribeUser();
    };
  
    const subscribeUser = () => {
      const { email, name } = formData;
      subscribeToMailchimp(email, name);
    };
  
    //fullpage js
    useEffect(() => {
      new fullpage('#fullpage', {
        autoScrolling: true,
        scrollHorizontally: true
      });
    }, []);

  return (
    <div className="App" style={{backgroundColor:"black"}}>
      <div id="fullpage" style={{backgroundColor:"black"}}>

      <div class="section" style={{backgroundColor:"black"}}>
      <div class="row" style={{color:"white", marginLeft:"0px"}}>
        <div class="col-md-4" style={{marginTop:"10px",marginRight:"90px",marginLeft:"30px"}}>
          <img src={logo} alt='Logo' className='App-logo'/>
          <span style={{width:"50%",fontSize:"30px"}}><b>PINDOGO</b></span>
        </div>
        <div class="col-md-1" style={{marginTop:"30px", color:"#326CF9"}}>
          <a style={{textDecoration:"none",fontSize:"20px", color:"#326CF9"}} exact href="/" activeClassName="active-link">
            <b>Home</b></a></div>
        <div class="col-md-3" style={{marginTop:"30px", color:"grey"}}>
          <a style={{textDecoration:"none",color:"grey",fontSize:"20px"}} href="/" activeClassName="active-link">
            <b>Mission</b></a></div>
        <div class="col-md-2" style={{marginTop:"30px", marginRight:"0px"}}>
          <a href='https://pindogo.com/login' style={{padding:"15px",marginLeft:"30%",width:"150px", fontSize:"20px", backgroundColor:"#326CF9"}} class="btn btn-primary">
            Beta Login</a></div>
      </div>
      <br></br> <br></br>

      <div style={{width:"100%"}}>
      <center>
        <p style={{marginBottom:"-35px",fontSize:"80px",color:"white", fontWeight:"bold"}}>Own your audience</p>
        <span style={{backgroundImage: "linear-gradient(45deg, #e911c5, #f0a330)",
        backgroundClip:"text",WebkitBackgroundClip: "text",color: "transparent",fontSize:"80px",fontWeight:"bold"}}>
          So you don't lose them
        </span><br></br><br></br><br></br>
        {/* <img class=" -7" src={group5} style={{width:"50%"}} alt="vid"/> */}
    
        <iframe style={{borderRadius:"20px"}}
         
         src="https://player.vimeo.com/video/852115036?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479">
        </iframe>
      </center><br></br><br></br> 
      </div>
      <center>
        <span style={{fontSize:"30px",color:"white"}} class=" -12">
          We want you to be the first to know when we launch<br></br>
          Join the waitlist.
        </span><br></br><br></br><br></br>
        <a href="/#section" id="join" class="btn btn-primary" style={{fontSize:"20px" ,padding:"20px",width:"250px",backgroundColor:"#306EF7" ,marginBottom:"10px"}}>
          Join The Waitlist</a>
        <a href='https://pindogo.com/login' class="btn btn-primary" style={{fontSize:"20px" ,padding:"20px",width:"250px",backgroundColor:"black",marginBottom:"10px"}}>
          Beta Login</a>
        <br></br></center> 
        <div class="row1" style={{justifyContent:"center"}}>
            <div class="">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 29 29" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8058 5.25708C18.5273 5.25708 22.3549 9.08459 22.3549 13.8061C22.3549 18.5276 18.5273 22.3551 13.8058 22.3551C9.08435 22.3551 5.25684 18.5276 5.25684 13.8061C5.25684 9.08459 9.08435 5.25708 13.8058 5.25708Z" fill="#0FC65C"/>
            <path opacity="0.24" fill-rule="evenodd" clip-rule="evenodd" d="M14.3 0C22.1977 0 28.6 6.40234 28.6 14.3C28.6 22.1977 22.1977 28.6 14.3 28.6C6.40234 28.6 0 22.1977 0 14.3C0 6.40234 6.40234 0 14.3 0Z" fill="#0FC65C"/>
            </svg>
            </div>
            <div class="" ><span style={{fontSize:"20px" ,color:"white"}}><b>150,000+</b></span></div>
            <div class="" ><span style={{fontSize:"20px" , color:"grey"}}>creators have already joined</span></div>
        </div>
        </div>
        

        <div class="section" style={{backgroundColor:"black"}}>
        <span class=" -1" style={{fontSize:"50px",color:"white", fontWeight:"bold"}}>
          <center>Why Entrepreneurs Love Pindogo</center>
        </span><br></br>
        <div class="row">
        <div class="col-md-0" ></div>
          <div class="col-md-4">
            <center><i class="fas fa-smile-beam  -3" style={{color:"yellow",fontSize: "25px", marginRight:"3px"}}></i>
            <span class=" -2" style={{color:"white",fontSize:"27px"}}>Unlimited Meeting</span><br></br>
            <span class=" -3" style={{color:"grey",fontSize:"17px"}}>Never worry about losing your audience.</span></center>
          </div>
          <div class="col-md-4" style={{marginRight:"0px"}}>
            <center><i class="fas fa-laugh-beam" style={{color:"yellow",fontSize: "25px", marginRight:"3px"}}></i>
            <span style={{color:"white",fontSize:"27px"}}>Paid Groups</span><br></br>
            <span style={{color:"grey",fontSize:"17px"}}>Just share one link, We'll do the rest.</span></center>
          </div>
          <div class="col-md-4">
            <center><i class="fas fa-face-grin-squint-tears" style={{color:"yellow",fontSize: "25px", marginRight:"3px"}}></i>
            <span style={{color:"white",fontSize:"27px"}}>More Usefull Time</span><br></br>
            <span style={{color:"grey",fontSize:"17px"}}>Spend less time on marketing tools.</span></center>
          </div>
          <div class="col-md-0" ></div>
        </div>
        </div>
        
        <div class="section" style={{backgroundColor:"black"}}>
        <center>
          <h2 class=" -4" style={{fontSize:"60px",color:"white"}}>Not your regular social media</h2>
            <div style={{width:"70%", fontSize:"24px", color:"grey"}}><span class=" -5">A different breed in the social media landscape, Pindogo is more than a platform it's a revolution. 
            We're here to reshape how you connect, engage, and most importantly, profit. Created with entrepreneurs,
            influencers, and trailblazers in mind, Pindogo combines social networking, business networking, 
            and video conferencing in one dynamic package.</span></div><br></br><br></br>
            <a href='/' class="btn btn-primary" style={{padding:"20px", width:"250px" ,backgroundColor:"black",marginBottom:"10px"}}>All About US</a> 
        </center>
        </div >
        

        
        <div class="section" style={{backgroundColor:"black"}}>
        <div class="row">
        <div class="col-md-1"></div>
          <div class="col-md-5">
            <p style={{color:"white",fontSize:"20px"}}>Virtual Meetings</p>
            <h2 style={{fontSize:"40px",color:"white"}}>Manage all. Your</h2>
            <h2 style={{fontSize:"40px",color:"#1a81e0"}}>Events & Trainings</h2>
            <span style={{fontSize:"18px",color:"grey"}}>
                  Do you find yourself lost in the shuffle of multiple platforms? 
                  Pindogo is your answer. Here, we offer cutting-edge features 
                  akin to Zoom, but without the price tag! Enjoy unlimited meeting 
                  minutes, spacious video conferencing rooms, innovative breakout
                  rooms, and interactive whiteboards, all for free. We believe in
                  accessibility, and Pindogo is testament to that.
            </span><br></br><br></br>
            <a href='#section' id='join' class="btn btn-primary" style={{backgroundColor:"#306EF7" ,width:"50%",padding:"15px",fontSize:"20px"}}>
              Join the Waitlist</a> 
            <a href='https://pindogo.com/login' id="beta" class="btn btn-primary" style={{width:"40%",padding:"15px",backgroundColor:"black",fontSize:"20px"}}>
              Beta Login</a> 
          </div>
          <div class="col-md-6" style={{marginTop:"2%"}}>
            <img class=" -6" style={{width:"100%"}} src={screen} alt='screen'></img>
          </div>
          <div class="col-md-0"></div>
        </div>
        </div >
       
        <div class="section" style={{backgroundColor:"black"}}>
        <center>
          <h2 style={{fontSize:"60px",color:"white"}} class=" -13">Do so much more with Pindogo</h2>
          <div style={{width:"60%"}}>
            <span style={{fontSize:"18px",color:"grey"}} class=" -14">
              Join us at Pindogo and step into a world of unprecedented opportunities.
              Grow your network, boost your earnings, and challenge the status quo alongside us. 
              Pindogo – where social media meets success. Join the revolution today!
          </span>
          </div>
        </center>
        </div >


        <div class="section" style={{backgroundColor:"black"}}>
        <div class="row" style={{marginTop:"40px"}}>
          <div class="col-md-1"></div>
          <div class="col-md-5  -15" style={{backgroundColor:"#131212", marginRight:"10px",padding:"20px"}}>
            <p  style={{fontSize:"25px",color:"green"}}>#pindogo</p>
            <span style={{fontSize:"55px",color:"grey"}}><b>You Are Your</b></span><br></br>
            <span style={{fontSize:"55px", color:"white"}}><b>Pindogo</b></span><br></br><br></br>
            <img src={mobile} style={{width:"100%"}} alt="mobile"/>
          </div>
          <div class="col-md-5 " style={{height:"480px",backgroundColor:"#131212",padding:"20px"}}>
            <p  style={{fontSize:"25px",color:"green"}}>Core Features</p><br></br><br></br><br></br>
            <span style={{fontSize:"50px",color:"grey"}}><b>Chat.</b></span><br></br>
            <span style={{fontSize:"50px",color:"grey" }}><b>Meet.</b></span><br></br>
            <span style={{fontSize:"50px",color:"white"}}><b>Profile.</b></span><br></br>
            <span style={{fontSize:"50px",color:"grey" }}><b>Go Live.</b></span><br></br>
            <span style={{fontSize:"50px",color:"grey"}}><b>Make Money.</b></span><br></br>
          </div>

          <div class="col-md-1"></div>
        </div><br></br><br></br><br></br>
        <center>
          <a href='#section' class="btn btn-primary" style={{backgroundColor:"#326CF9",fontSize:"20px",padding:"10px",width:"200px"}}>
            Join the Waitlist
          </a>
        </center>
        </div >

        <div class="section" style={{backgroundColor:"black"}}>
        <center>
          <h2 style={{fontSize:"70px",color:"white"}}>Send Messages & Create Groups</h2>
          <div style={{width:"50%"}}>
            <span style={{fontSize:"25px",color:"grey"}}>
              No more going through a other Chat platform. Reach and engage
              your audience directly over in Pindogo.
            </span>
          </div>
        </center>
        </div >
        

        <div class="section" style={{backgroundColor:"black"}}>
        <div class="row">
        <div class="col-md-1"></div>
          <div class="col-md-5">
            <p style={{fontSize:"20px",color:"white"}}>Messages & Groups</p><b>
            <span style={{fontSize:"40px",color:"white"}}>Chat with your</span><br></br>
            <span style={{fontSize:"40px",color:"white"}}>Audience </span>
            <span style={{color:"#1a81e0",fontSize:"40px"}}>
              Directly.
              </span></b><br></br>
            <ol style={{color:"white", marginLeft:"-30px", fontSize:"20px"}}>
                 <li>Interact with your audience in a private chat</li>
                 <li>Create groups with like minds and engage in wealth building.</li>
            </ol><br></br>
            <a href='#section' class="btn btn-primary" style={{padding:"20px",backgroundColor:"#326CF9"}}>Join the Waitlist</a> 
            <a href='https://pindogo.com/login' class="btn btn-primary" style={{padding:"20px",backgroundColor:"black",marginLeft:"10px"}}>
              Beta Login</a> 
          </div><br></br><br></br>
          <div class="col-md-5">
            <img class=" -8" src={screen1} style={{width:"100%"}} alt='screen1'></img>
          </div>
          <div class="col-md-1"></div>
        </div>
        </div >
        

        
        <div class="section" id="section" style={{backgroundColor:"black"}}>
        <div id="msg" class="alert alert-success .alert-dismissible" 
        style={{display:"none",margin:"auto",width:"60%",textAlign:"center"}}>
        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
          <p class="">
            <strong>Success!</strong> Subscription successful.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
        <center>
          <h2 class=" -10" style={{fontSize:"100px",color:"white"}}>Join the waitlist</h2><br></br><br></br><br></br><br></br><br></br>
          <div class="row">
            <div class="col-md-1"></div>
            <div class="form-group col-md-5">
              <div class="input-group">
                <input 
                 name="name"
                 value={formData.name}
                 onChange={handleChange}
                 required
                 style={{backgroundColor:"rgba(0,0,0,0)", border:"none"}} type="text" 
                 class="form-control" id="exampleInputAmount" placeholder="Full Name" />
                <div class="input-group-addon" style={{backgroundColor:"rgba(0,0,0,0)", border:"none"}}>
                <i class="fa-solid fa-user" style={{color:"white",fontSize: "15px", marginRight:"3px"}}></i>
                </div>
              </div>
            </div>
            <div class="form-group col-md-5">
              <div class="input-group">
                <input name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required style={{backgroundColor:"rgba(0,0,0,0)", border:"none"}} type="email"
                  class="form-control" id="exampleInputAmount" placeholder="Email" />
                <div class="input-group-addon" style={{backgroundColor:"rgba(0,0,0,0)", border:"none"}}>
                  <i class="fa-regular fa-eye" style={{color:"white",fontSize: "15px", marginRight:"3px"}}></i>
                </div>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div><br></br><br></br>
         <button type='submit' class="btn btn-primary" 
         style={{backgroundColor:"#326CF9" ,padding:"15px",width:"25%",fontSize:"20px"}}>
           Add Me
         </button>
           </center>
           </form>
           </div >
           
          


        <div class="section" style={{backgroundColor:"black"}}>
        <center>
         <img class="" style={{width:"100%",height:"100%"}} src={audience} alt='banner'/>
         </center>
         </div >

         <div class="section" style={{backgroundColor:"black"}}>
         <center>
         <img class=" -9" id='flogo' src={logo} alt='logo'/>
         <h1 style={{color:"white", marginTop:"-2%",fontSize:"75px"}}><b>Don't miss out</b></h1>
         <span style={{color:"white", fontSize:"35px"}}>Get early access by joining our waitlist</span><br></br><br></br>
         <a href='#section' id="fbtn" class="btn btn-primary" style={{backgroundColor:"#326CF9" ,padding:"20px", fontSize:"20px"}}>
           Join the waitlist</a>
        </center><br></br>
        <div class="row1" style={{justifyContent:"center"}}>
            <div class="">
            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8058 5.25708C18.5273 5.25708 22.3549 9.08459 22.3549 13.8061C22.3549 18.5276 18.5273 22.3551 13.8058 22.3551C9.08435 22.3551 5.25684 18.5276 5.25684 13.8061C5.25684 9.08459 9.08435 5.25708 13.8058 5.25708Z" fill="#0FC65C"/>
              <path opacity="0.24" fill-rule="evenodd" clip-rule="evenodd" d="M14.3 0C22.1977 0 28.6 6.40234 28.6 14.3C28.6 22.1977 22.1977 28.6 14.3 28.6C6.40234 28.6 0 22.1977 0 14.3C0 6.40234 6.40234 0 14.3 0Z" fill="#0FC65C"/>
            </svg> 
            </div>
            <div class="" ><span style={{color:"white", fontSize:"20px"}}>150,000+ &nbsp;</span></div>
            <div class="" ><span style={{color:"grey", fontSize:"20px"}}> creators have already joined</span></div>
        </div>
        </div >
        </div>
    </div>
  );
}

export default App;
   // Function to check if the element is in the viewport
  //  function isElementInViewport(el) {
  //   const rect = el.getBoundingClientRect();
  //   return (
  //     rect.top >= 0 &&
  //     rect.left >= 0 &&
  //     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  //     rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  //   );
  // }

  // Function to handle scroll event
  // function handleScroll() {
  //   const scrollElements = document.querySelectorAll('.');
  //   scrollElements.forEach((element) => {
  //     if (isElementInViewport(element)) {
  //       element.classList.add('animate');
  //     }
  //   });
  // }

  // Add event listener for scroll
  // window.addEventListener('scroll', handleScroll);