import logo from './logo.png';
import mobile from './Mobile.png';
import screen1 from './Screen1.png';
import screen from './Screen.png';
import audience from './Audience.png';
import group5 from './Group5.png';
import './App.css';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';


function Mission() {
  return (
    <div className="App">
      <div class="row" style={{color:"white", marginLeft:"0px"}}>
        <div class="col-md-4" style={{marginTop:"10px",marginRight:"90px",marginLeft:"30px"}}>
          <img src={logo} alt='Logo' className='App-logo'/>
          <span style={{width:"50%",fontSize:"30px"}}><b>PINDOGO</b></span>
        </div>
        <div class="col-md-1" style={{marginTop:"30px"}}>
          <a style={{textDecoration:"none",fontSize:"20px", color:"grey"}}  href="/" activeClassName="active-link">
            <b>Home</b></a></div>
        <div class="col-md-3" style={{marginTop:"30px"}}>
          <a style={{textDecoration:"none",color:"#326CF9",fontSize:"20px"}} href="/mission" activeClassName="active-link">
            <b>Mission</b></a></div>
        <div class="col-md-1" style={{marginTop:"30px", marginRight:"0px"}}>
          <button style={{padding:"15px",width:"150px", fontSize:"20px", backgroundColor:"#326CF9"}} class="btn btn-primary">Beta Login</button></div>
      </div><br></br> <br></br>
      <div style={{width:"100%"}}>
    
      </div>
    
        <center>
        
          <img style={{width:"10%"}} src={logo} alt='logo'/> 
          <span style={{color:"white",fontSize:"38px"}}><b>PINDOGO</b></span><br></br><br></br>
          <p style={{fontSize:"20px",color:"#306EF7"}}>OUR MISSION</p>
          <span style={{color:"white",fontSize:"65px"}}><b>We exist to help Entrepreneurs</b></span><br></br>
          <span style={{color:"white",fontSize:"20px"}}>
              We believe that social platforms are taking advantage of creators.<br></br>
We want to change that and help creators fight back. 
        </span>
          <br></br><br></br><br></br><br></br><br></br><br></br>
          
          <span style={{color:"white",fontSize:"65px"}}><b>What makes Pindogo special</b></span><br></br><br></br>
          <div style={{width:"70%",textAlign:"justify"}}>
          <div class="">
          <span  style={{color:"white",fontSize:"20px",textAlign:"justify"}}>
            Ad-Free Experience: For users seeking a seamless, uninterrupted browsing experience
            our premium service provides an ad-free interface. This removes potential distraction
            s and optimizes user engagement with content and interactions on the platform.
           </span><br></br><br></br>
           <span  style={{color:"white",fontSize:"20px",textAlign:"justify"}}>
            Premium Advertising Packages: We offer businesses the opportunity to engage directly
            with our influential and dynamic user base through our premium advertising packages.
            These packages grant access to high-visibility slots across the platform, enabling
            targeted promotional strategies.
            </span><br></br><br></br>
            </div>
            <span style={{color:"white",fontSize:"20px",textAlign:"justify"}}>
            Advanced Analytics: We understand the need for data-driven decision-making.
            Premium users gain access to in-depth analytics about their posts, profile, and interactions,
            empowering them to fine-tune their content strategies, better engage their followers
            and maximize their impact on the platform.
            </span><br></br><br></br>
            <span  style={{color:"white",fontSize:"20px",textAlign:"justify"}}>
            Priority Customer Support: Our premium users enjoy a high level of customer service, 
            with priority access to our dedicated support team.
            </span><br></br><br></br>
            <span  style={{color:"white",fontSize:"20px",textAlign:"justify"}}>
            Premium Networking Events: We facilitate exclusive networking opportunities via virtual events,
            webinars, and masterclasses. Premium users can learn from and interact with industry leaders,
            successful entrepreneurs, and influencers, thus boosting their own knowledge and professional
            network. Premium Badge: To highlight the commitment of our premium users, we adorn their
            profiles with a distinctive premium badge. This badge enhances their visibility and reinforces
            their prestige within the Pindogo community. Sponsored Content Opportunities: We also offer 
            our premium users potential income opportunities through sponsored content and brand 
            collaborations, adding value to their platform experience and contributing to their personal 
            brand grow there is somethings I think we should also offer in time 
            </span>
            <br></br><br></br>
            <span style={{color:"white",fontSize:"20px",textAlign:"justify"}}>
            Pindogo Vault: A secure, integrated digital wallet system that supports the platform's unique payout structure and provides users with easy access to their earnings, ensuring seamless financial transactions and transparency.
            </span><br></br><br></br>
            <span style={{color:"white",fontSize:"20px",textAlign:"justify"}}>
            Pindogo Insights: A proprietary analytics tool that offers personalized recommendations for growth strategies, content optimization, and audience engagement, empowering users to make data-driven decisions and enhance their platform presence.
            </span><br></br><br></br>
            <span style={{color:"white",fontSize:"20px",textAlign:"justify"}}>
            Pindogo Mentorship: A mentorship program that connects users with experienced professionals who can offer guidance, advice, and support, promoting personal and professional development within the community.
            </span>
        </div>
          <br></br>

          <img class="" style={{width:"15%"}} src={logo} alt='logo'/>
         <h1 style={{color:"white", marginTop:"-2%",fontSize:"75px"}}><b>Don't miss out</b></h1>
         <span style={{color:"white", fontSize:"35px"}}>Get early access by joining our waitlist</span><br></br><br></br>
         <a href='/' class="btn btn-primary" style={{backgroundColor:"#326CF9" ,width:"14%",padding:"10px", fontSize:"20px"}}>Join the waitlist</a>
        </center><br></br>
        <div class="row1" style={{justifyContent:"center"}}>
            <div class="">
            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8058 5.25708C18.5273 5.25708 22.3549 9.08459 22.3549 13.8061C22.3549 18.5276 18.5273 22.3551 13.8058 22.3551C9.08435 22.3551 5.25684 18.5276 5.25684 13.8061C5.25684 9.08459 9.08435 5.25708 13.8058 5.25708Z" fill="#0FC65C"/>
              <path opacity="0.24" fill-rule="evenodd" clip-rule="evenodd" d="M14.3 0C22.1977 0 28.6 6.40234 28.6 14.3C28.6 22.1977 22.1977 28.6 14.3 28.6C6.40234 28.6 0 22.1977 0 14.3C0 6.40234 6.40234 0 14.3 0Z" fill="#0FC65C"/>
            </svg> 
            </div>
            <div class="" ><span style={{color:"white", fontSize:"22px"}}>150,000+ &nbsp;</span></div>
            <div class="" ><span style={{color:"grey", fontSize:"22px"}}> creators have already joined</span></div>
        </div><br></br><br></br><br></br>
     
    </div>
  );
}

export default Mission;

    // Function to check if the element is in the viewport
    // function isElementInViewport(el) {
    //     const rect = el.getBoundingClientRect();
    //     return (
    //       rect.top >= 0 &&
    //       rect.left >= 0 &&
    //       rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    //       rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    //     );
    //   }
  
      // Function to handle scroll event
    //   function handleScroll() {
    //     const scrollElements = document.querySelectorAll('.scroll-element');
    //     scrollElements.forEach((element) => {
    //       if (isElementInViewport(element)) {
    //         element.classList.add('animate');
    //       }
    //     });
    //   }
  
      // Add event listener for scroll
    //   window.addEventListener('scroll', handleScroll);